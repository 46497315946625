.module {
  position: relative;

  display: flex;
  flex-direction: column;
  width: 670px;
  max-width: 100%;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  overflow: hidden;

  background-color: #f9f9f9;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.name {
  font-size: 22px;
  font-weight: 700;
}

.address {
  margin-bottom: 12px;

  font-size: 14px;
}

.link {
  margin-bottom: 8px;
  padding-top: 8px;
  padding-bottom: 8px;

  font-size: 16px;
  text-decoration: underline;
}

.text {
  margin-bottom: 12px;

  font-size: 16px;
}

.slider {
  position: relative;
}

.slide {
  position: relative;

  display: flex;
  padding-bottom: 69%;

  img {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.prev {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  aspect-ratio: 1;

  transform: translateY(-50%);

  &::before {
    width: 33px;
    aspect-ratio: 1;

    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(4px);

    transition: transform 0.2s;

    content: '';
  }

  &::after {
    position: absolute;
    top: 17px;
    left: 18px;

    width: 7px;
    aspect-ratio: 1;

    border-top: 1px solid #000;
    border-left: 1px solid #000;
    transform: rotate(-45deg);

    content: '';
  }

  &:hover {
    &::before {
      transform: scale(1.1);
    }
  }

  &:active {
    &::before {
      transform: scale(0.9);
    }
  }
}

.next {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  aspect-ratio: 1;

  transform: translateY(-50%);

  &::before {
    width: 33px;
    aspect-ratio: 1;

    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(4px);

    transition: transform 0.2s;

    content: '';
  }

  &::after {
    position: absolute;
    top: 17px;
    right: 18px;

    width: 7px;
    aspect-ratio: 1;

    border-top: 1px solid #000;
    border-right: 1px solid #000;
    transform: rotate(45deg);

    content: '';
  }

  &:hover {
    &::before {
      transform: scale(1.1);
    }
  }

  &:active {
    &::before {
      transform: scale(0.9);
    }
  }
}

@media (min-width: 768px) {
  // .title {
  //   font-size: 30px;
  // }
}

@media (hover: hover) {
  // .button {
  //   &:hover {
  //     background-color: lighten(#3a99a3, 10%);
  //   }
  // }
}
