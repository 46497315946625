$transition-time: 0.3s;

.module {
  position: relative;
  // position: absolute;
  // top: 0;
  // left: 0;

  grid-column: 1 / -1;
  grid-row: 1 / -1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 100vw;
  // max-width: 100%;
  // min-height: 100%;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  // overflow-x: hidden;

  background-color: rgba(0, 0, 0, 0.7);

  &:global(.enter) {
    opacity: 0;

    .content {
      transform: translateY(120px);
    }
  }

  &:global(.enterActive) {
    transition: opacity $transition-time;
    opacity: 1;

    .content {
      transition: transform $transition-time;
      transform: translateY(0);
    }
  }

  &:global(.exit) {
    transition: opacity $transition-time;
    opacity: 1;

    .content {
      transition: transform $transition-time;
      transform: translateY(0);
    }
  }

  &:global(.exitActive) {
    opacity: 0;

    .content {
      transform: translateY(120px);
    }
  }
}

.content {
  max-width: 100%;
}

@media (min-width: 768px) {
  .module {
    padding-top: 42px;
  }
}
