.question {
  background-position: center;
  background-size: cover;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 64px;
    padding-bottom: 70px;
  }

  &__title {
    margin-bottom: 18px;

    font-size: 25px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }

  &__subtitle {
    max-width: 310px;
    margin-bottom: 48px;

    line-height: 1.3902;
    text-align: center;
    text-transform: uppercase;
  }
}

@media (min-width: 576px) {
  // .question {
  //   &__image {
  //     margin-left: calc((100vw - 540px) / -2);
  //     margin-right: calc((100vw - 540px) / -2);
  //   }
  // }
}

@media (min-width: 768px) {
  .question {
    &__content {
      padding-top: 104px;
      padding-bottom: 93px;
    }

    &__title {
      margin-bottom: 28px;

      font-size: 35px;
    }

    &__subtitle {
      max-width: none;

      font-size: 25px;
    }
  }
}

@media (min-width: 1200px) {
  .question {
    &__content {
      padding-top: 98px;
      padding-bottom: 99px;
    }

    &__subtitle {
      margin-bottom: 59px;
    }
  }
}
