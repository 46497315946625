.module {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 253px;
}

.name {
  margin-bottom: 34px;
}

.phone {
  margin-bottom: 27px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 52px;
  margin-bottom: 13px;

  font-size: 15px;
  text-transform: uppercase;

  color: #fff;
  background-color: #92855b;

  transition: background 0.2s;

  &:hover {
    background-color: darken(#92855b, 10%);
  }
}

@media (min-width: 768px) {
  .module {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 700px;
  }

  .name {
    width: 210px;
    margin-right: 30px;
    margin-bottom: 0;
  }

  .phone {
    width: 210px;
    margin-right: 30px;
    margin-bottom: 0;
  }

  .button {
    width: 220px;
    margin-bottom: 0;
  }

  .policy {
    width: 223px;
    margin-top: 12px;
  }
}

@media (min-width: 1200px) {
  .module {
    width: 780px;
  }

  .name {
    width: 250px;
  }

  .phone {
    width: 250px;
  }
}
