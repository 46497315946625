@import '/src/styles/utils/functions';

$corner-color: #a59c7c;

.module {
  position: relative;

  display: flex;
  align-items: center;
  height: 32px;

  border-bottom: 1px solid #595959;
  background-image: svg-encode(
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 10"><polyline points="1 3, 6 8, 11 3" stroke="#{$corner-color}" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" fill="none" /></svg>'
  );
  background-repeat: no-repeat;
  background-position: right 0 center;
  background-size: 10px;
}

.title {
  font-size: 14px;
  text-transform: uppercase;

  color: rgba(0, 0, 0, 0.69);
}

.select {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 14px;

  cursor: pointer;
}
