.footer {
  &__top {
    background-color: map-get($colors, 'bg');
  }

  &__top-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 53px;
    padding-bottom: 53px;
  }

  &__logo {
    width: 160px;
    margin-bottom: 30px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__phones {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
  }

  &__phone {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.22;

    transition: color 0.2s;

    &:hover {
      color: map-get($colors, 'main');
    }
  }

  &__callback {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 179px;
    height: 52px;

    font-size: 14px;
    text-transform: uppercase;

    border: 2px solid map-get($colors, 'main');

    transition: color 0.2s, background 0.2s;

    &:hover {
      color: #fff;
      background-color: map-get($colors, 'main');
    }
  }

  &__bottom {
    background-color: map-get($colors, 'main');
  }

  &__bottom-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 26px;
    padding-bottom: 35px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 25px;
    margin-bottom: 25px;

    button {
      font-size: 14px;
      line-height: 1.21;

      color: #fff;
    }
  }

  &__copyright {
    font-size: 14px;
    line-height: 1.21;

    color: #fff;
  }
}

@media (min-width: 768px) {
  .footer {
    &__top-content {
      flex-direction: row;
      justify-content: space-between;

      padding-top: 30px;
      padding-bottom: 35px;
    }

    &__logo {
      margin-bottom: 0;
    }

    &__info {
      flex-direction: row;
    }

    &__phones {
      align-items: flex-end;
      margin-right: 23px;
      margin-bottom: 0;
    }

    &__bottom-content {
      // flex-direction: row;
      padding-top: 15px;
      padding-bottom: 17px;
    }

    &__buttons {
      // order: 1;
      flex-direction: row;
      column-gap: 26px;
      margin-bottom: 15px;

      button {
        font-size: 12px;
      }
    }

    &__copyright {
      // margin-right: auto;

      font-size: 12px;
    }
  }
}

@media (min-width: 1200px) {
  .footer {
    &__top {
      background-color: transparent;
    }

    &__top-content {
      padding-top: 13px;
      padding-bottom: 13px;
    }

    &__bottom-content {
      padding-bottom: 15px;
    }

    &__buttons {
      column-gap: 54px;

      button {
        font-size: 14px;
      }
    }

    &__copyright {
      font-size: 14px;
    }
  }
}
