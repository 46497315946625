.cafe {
  // background-color: map-get($colors, 'bg');

  &__content {
    padding-top: 60px;
    padding-bottom: 77px;
  }

  &__title {
    margin-bottom: 21px;

    font-size: 25px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }

  &__subtitle {
    margin-bottom: 38px;
    padding-bottom: 37px;

    font-size: 18px;
    line-height: 1.42;
    text-align: center;

    border-bottom: 2px solid map-get($colors, 'main');
  }

  &__text {
    margin-bottom: 21px;

    font-size: 14px;
    line-height: 1.64;
    text-align: center;
  }

  &__gallery {
    display: flex;
    flex-direction: column;
  }

  &__first {
    display: flex;
    margin-bottom: 10px;
  }

  &__one {
    display: flex;
    aspect-ratio: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__second {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
  }

  &__two {
    display: flex;
    width: calc(50% - 5px);
    aspect-ratio: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__three {
    display: flex;
    width: calc(50% - 5px);
    aspect-ratio: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__four {
    display: flex;
    width: calc(50% - 5px);
    aspect-ratio: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__five {
    display: flex;
    width: calc(50% - 5px);
    aspect-ratio: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media (min-width: 768px) {
  .cafe {
    &__content {
      padding-top: 71px;
    }

    &__title {
      margin-bottom: 46px;

      font-size: 35px;
    }

    &__subtitle {
      max-width: 620px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 21px;
      padding-bottom: 23px;

      font-size: 23px;
    }

    &__text {
      margin-bottom: 46px;

      font-size: 16px;
    }

    &__gallery {
      flex-direction: row;
      column-gap: 10px;
    }

    &__first {
      width: calc(50% - 5px);
      margin-bottom: 0;
    }

    &__second {
      width: calc(50% - 5px);
    }
  }
}

@media (min-width: 1200px) {
  .cafe {
    &__content {
      padding-top: 108px;
      padding-bottom: 116px;
    }

    &__text {
      max-width: 852px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 44px;
    }

    &__gallery {
      column-gap: 20px;
    }

    &__first {
      width: calc(50% - 10px);
    }

    &__second {
      row-gap: 20px;
      column-gap: 20px;
      width: calc(50% - 10px);
    }

    &__two {
      width: calc(50% - 10px);
    }

    &__three {
      width: calc(50% - 10px);
    }

    &__four {
      width: calc(50% - 10px);
    }

    &__five {
      width: calc(50% - 10px);
    }
  }
}
