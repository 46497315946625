@import '/src/styles/utils/functions';

$mark-color: #000;
$invert-mark-color: #fff;

.module {
  width: 16px;
  aspect-ratio: 1;

  border: 1px solid #000;
  cursor: pointer;

  appearance: none;

  &:checked {
    background-image: svg-encode(
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><polyline points="4 10, 8 14, 16 6" stroke="#{$mark-color}" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" fill="none" /></svg>'
    );
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &.inverted {
    // background-color: #fff;

    &:checked {
      background-image: svg-encode(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><polyline points="4 10, 8 14, 16 6" stroke="#{$invert-mark-color}" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" fill="none" /></svg>'
      );
    }
  }
}
