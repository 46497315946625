.quiz {
  background-repeat: no-repeat;
  background-position-x: -300px;
  background-size: cover;
  background-color: #faf9f7;

  &__content {
    padding-top: 57px;
    padding-bottom: 95px;
  }

  &__block {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 37px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 94px;

    background-color: #fff;
  }

  &__title {
    margin-bottom: 10px;

    font-size: 25px;
    font-weight: 600;
    line-height: 1.12;
    text-align: center;
  }

  &__subtitle {
    margin-bottom: 15px;

    font-size: 14px;
    font-weight: 500;
    line-height: 1.3902;
    text-transform: uppercase;
    text-align: center;

    color: map-get($colors, 'main');
  }
}

@media (min-width: 768px) {
  .quiz {
    background-position-x: -500px;

    &__content {
      display: flex;
      justify-content: flex-end;
      padding-top: 93px;
    }

    &__block {
      max-width: none;
      width: 543px;
      margin-left: 0;
      margin-right: 0;
      padding-top: 82px;
      padding-left: 68px;
      padding-bottom: 85px;
    }

    &__title {
      margin-bottom: 27px;

      font-size: 35px;
      text-align: left;
    }

    &__subtitle {
      max-width: 430px;

      font-size: 20px;
      text-align: left;
    }
  }
}

@media (min-width: 1200px) {
  .quiz {
    background-position-x: 0;
    background-size: contain;

    &__content {
      padding-top: 110px;
      padding-bottom: 110px;
    }

    &__block {
      position: relative;

      display: flex;
      width: 970px;
      padding-top: 159px;
      padding-left: 92px;
      padding-bottom: 114px;
    }

    &__title {
      position: absolute;
      top: 82px;
      left: 92px;

      margin-bottom: 0;
    }

    &__subtitle {
      width: 440px;
      margin-right: 20px;
      margin-bottom: 0;
      padding-top: 20px;
    }
  }
}
