html {
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;

  // color: map-get($colors, 'black');
}

// input,
// textarea {
//   font: inherit;
// }

// a:focus,
// button:focus,
// input:focus,
// select:focus,
// textarea:focus {
//   // box-shadow: none;
//   outline: none;
//   outline-offset: none;
// }

td {
  padding: 10px;

  border: 1px solid #e5e5e5;
}
