.instagram {
  // background-color: map-get($colors, 'bg');

  &__content {
    padding-top: 30px;
  }

  &__title {
    margin-bottom: 19px;

    font-size: 25px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    margin-bottom: 30px;
  }

  &__item {
    display: flex;
    width: calc(33.33333% - 20px / 3);
    aspect-ratio: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 202px;
    height: 52px;
    margin-left: auto;
    margin-right: auto;

    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;

    border: 1px solid #000;
    color: map-get($colors, 'main');

    transition: border 0.2s, color 0.2s, background 0.2s;

    &:hover {
      border-color: map-get($colors, 'main');
      color: #fff;
      background-color: map-get($colors, 'main');
    }
  }
}

@media (min-width: 768px) {
  .instagram {
    &__content {
      padding-top: 61px;
    }

    &__title {
      margin-bottom: 59px;

      font-size: 35px;
    }

    &__list {
      row-gap: 25px;
      column-gap: 25px;
      margin-bottom: 44px;
    }

    &__item {
      width: calc(33.33333% - 50px / 3);
    }
  }
}

@media (min-width: 1200px) {
  .instagram {
    &__content {
      position: relative;

      padding-top: 108px;
    }

    &__title {
      margin-bottom: 57px;

      text-align: left;
    }

    &__list {
      row-gap: 30px;
      column-gap: 30px;
      margin-bottom: 0;
    }

    &__item {
      width: calc(25% - 30px * 3 / 4);
    }

    &__link {
      position: absolute;
      top: 108px;
      right: 0;
    }
  }
}
