.advantages {
  position: relative;

  background-position: center;
  background-size: cover;

  background-color: #faf9f7;

  #advantages {
    position: absolute;
    top: -63px;
  }

  &__content {
    padding-top: 55px;
    padding-bottom: 62px;
  }

  &__title {
    margin-bottom: 43px;

    font-size: 25px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 39px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 202px;

    strong {
      margin-bottom: 17px;
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;

      font-weight: 500;
      line-height: 1.25;
      text-align: center;

      border: 1px solid map-get($colors, 'main');
      color: map-get($colors, 'main');
    }

    span {
      font-size: 12px;
      line-height: 1.5161;
      text-align: center;
    }
  }
}

@media (min-width: 768px) {
  .advantages {
    #advantages {
      top: -106px;
    }

    &__content {
      padding-top: 76px;
      padding-bottom: 50px;
    }

    &__title {
      margin-bottom: 38px;

      font-size: 35px;
    }

    &__list {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      column-gap: 40px;
    }

    &__item {
      width: calc(33.33333% - 80px / 3);

      strong {
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1 / 0.8;
        margin-bottom: 22px;
      }

      span {
        font-size: 14px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .advantages {
    &__content {
      padding-top: 98px;
      padding-bottom: 103px;
    }

    &__title {
      margin-bottom: 54px;
    }

    &__item {
      width: calc(20% - 40px * 4 / 5);

      strong {
        font-size: 20px;
      }
    }
  }
}
