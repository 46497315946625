.module {
  display: flex;
  justify-content: center;
}

.prev {
  position: relative;

  height: 52px;
  aspect-ratio: 1;
  margin-right: 13px;

  border: 1px solid #92855b;

  transition: border 0.2s, background 0.2s;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 20px;
    height: 1px;

    background-color: #92855b;
    transform: translate(-50%, -50%);

    transition: background 0.2s;

    content: '';
  }

  &::after {
    position: absolute;
    top: 19px;
    left: 15px;

    width: 10px;
    height: 10px;

    border-left: 1px solid #92855b;
    border-bottom: 1px solid #92855b;
    transform: rotate(45deg);

    transition: border 0.2s;

    content: '';
  }

  &:hover {
    border-color: #92855b;
    background-color: #92855b;

    &::before {
      background-color: #fff;
    }

    &::after {
      border-color: #fff;
    }
  }
}

.next {
  height: 52px;
  flex-grow: 1;

  font-size: 15px;
  text-align: center;
  text-transform: uppercase;

  color: #fff;
  background-color: #92855b;

  transition: background 0.2s;

  &:hover {
    background-color: darken(#92855b, 10%);
  }
}

.confirm {
  height: 52px;
  width: 100%;

  font-size: 15px;
  text-align: center;
  text-transform: uppercase;

  color: #fff;
  background-color: #92855b;

  transition: background 0.2s;

  &:hover {
    background-color: darken(#92855b, 10%);
  }
}
