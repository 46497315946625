.auto {
  &__content {
    display: flex;
    justify-content: center;
    padding-top: 30px;
  }

  &__link {
    font-size: 25px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    text-decoration: underline;
    transition: color 0.2s;

    &:hover {
      color: #92855b;
    }
  }
}

@media (min-width: 768px) {
  .auto {
    &__content {
      padding-top: 61px;
    }

    &__link {
      font-size: 35px;
    }
  }
}

@media (min-width: 1200px) {
  .auto {
    &__content {
      position: relative;

      padding-top: 108px;
    }

    &__link {
      text-align: left;
    }
  }
}
