.module {
  height: 32px;
  width: 100%;
  // padding-left: 22px;
  // padding-right: 22px;

  border-bottom: 1px solid #595959;

  &::placeholder {
    font-size: 14px;
    text-transform: uppercase;

    color: rgba(0, 0, 0, 0.69);
  }
}

@media (min-width: 768px) {
}
