.module {
  min-height: 155px;
  padding: 7px 0;

  border-bottom: 1px solid #595959;

  &::placeholder {
    font-size: 14px;
    line-height: 1.21;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.69);
  }
}
