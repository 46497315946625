.module {
  position: relative;

  width: 770px;
  max-width: 100%;
  padding-top: 64px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 80px;
  overflow: hidden;

  background-color: #f4f3f1;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  margin-bottom: 44px;

  font-size: 35px;
  font-weight: 600;
  line-height: 1.23;
  text-align: center;
}

.grade {
  margin-bottom: 38px;
}

.gradeTitle {
  margin-bottom: 16px;

  font-size: 14px;
  line-height: 1.21;
  text-align: center;
  text-transform: uppercase;

  color: rgba(0, 0, 0, 0.37);
}

.gradeList {
  display: flex;
  justify-content: space-between;

  label {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  input {
    margin-bottom: 7px;
  }

  span {
    font-size: 12px;
    font-weight: 300;
    line-height: 1.17;

    color: rgba(0, 0, 0, 0.54);
  }
}

.name {
  margin-bottom: 27px;
}

.email {
  margin-bottom: 27px;
}

.text {
  margin-bottom: 30px;
}

.policy {
  margin-bottom: 14px;
}

.button {
  align-self: center;
  width: 100%;
  height: 50px;
  margin-bottom: 10px;

  font-size: 14px;
  text-align: center;
  text-transform: uppercase;

  color: #fff;
  background-color: #92855b;

  transition: background 0.2s;

  &:hover {
    background-color: darken(#92855b, 10%);
  }
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
}
