.module {
  position: relative;
  overflow: hidden;
}

.wrapper {
  position: relative;

  display: flex;

  transition: left 0.2s;

  & > * {
    flex: 0 0 100%;
  }
}
