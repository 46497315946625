.gallery {
  // background-color: map-get($colors, 'bg');

  &__content {
    padding-top: 45px;
  }

  &__first {
    display: flex;
    column-gap: 10px;
    margin-bottom: 10px;
  }

  &__one {
    display: flex;
    width: calc(50% - 5px);
    aspect-ratio: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__two {
    display: flex;
    width: calc(50% - 5px);
    aspect-ratio: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__second {
    display: flex;
    margin-bottom: 10px;
  }

  &__three {
    display: flex;
    aspect-ratio: 1 / 0.69;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__third {
    display: flex;
    column-gap: 10px;
  }

  &__four {
    display: flex;
    width: calc(50% - 5px);
    aspect-ratio: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__five {
    display: flex;
    width: calc(50% - 5px);
    aspect-ratio: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media (min-width: 768px) {
  .gallery {
    &__content {
      display: flex;
      column-gap: 16px;
      padding-top: 38px;
    }

    &__first {
      flex-direction: column;
      row-gap: 16px;
      width: calc(33.33333% - 16px * 2 / 3);
      margin-bottom: 0;
    }

    &__one {
      width: 100%;
      aspect-ratio: 1 / 0.69;
    }

    &__two {
      width: 100%;
      aspect-ratio: 1 / 0.69;
    }

    &__second {
      width: calc(33.33333% - 16px * 2 / 3);
      margin-bottom: 0;
    }

    &__three {
      width: 100%;
    }

    &__third {
      flex-direction: column;
      row-gap: 16px;
      width: calc(33.33333% - 16px * 2 / 3);
    }

    &__four {
      width: 100%;
      aspect-ratio: 1 / 0.69;
    }

    &__five {
      width: 100%;
      aspect-ratio: 1 / 0.69;
    }
  }
}

@media (min-width: 1200px) {
  .gallery {
    &__content {
      column-gap: 30px;
      padding-top: 60px;
    }

    &__first {
      row-gap: 30px;
    }

    &__third {
      row-gap: 30px;
    }
  }
}
