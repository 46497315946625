.module {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 770px;
  max-width: 100%;
  padding-top: 89px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 78px;

  background-color: #fff;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.icon {
  position: relative;

  width: 54px;
  aspect-ratio: 1;
  margin-bottom: 38px;

  border-radius: 50%;
  background-color: #92855b;

  &::before {
    position: absolute;
    top: 19px;
    left: 17px;

    width: 21px;
    height: 11px;

    border-left: 1px solid rgba(255, 255, 255, 0.63);
    border-bottom: 1px solid rgba(255, 255, 255, 0.63);
    transform: rotate(-45deg);

    content: '';
  }
}

.title {
  margin-bottom: 34px;

  font-size: 35px;
  font-weight: 600;
  line-height: 1.23;
  text-align: center;

  color: #92855b;
}

.subtitle {
  max-width: 270px;

  line-height: 1.55;
  text-align: center;
}

@media (min-width: 1200px) {
}
