$color: #92855b;

.module {
  height: 100%;

  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(152, 152, 152, 0.13);

  :global {
    .DayPicker {
      &-NavButton {
        &--prev {
          left: 1.5em;
          right: auto;

          background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"%3E%3Cpolyline points="13 1, 4 10, 13 19" stroke="%23000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" /%3E%3C/svg%3E');
        }

        &--next {
          background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"%3E%3Cpolyline points="7 1, 16 10, 7 19" stroke="%23000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" /%3E%3C/svg%3E');
        }
      }

      &-Caption {
        text-align: center;

        & > div {
          font-weight: 700;
        }
      }

      &-Day {
        border-radius: 3px;

        &--disabled {
          color: #e7e7e7;
        }

        &--today {
          color: #fff;
          background-color: $color;

          &:hover {
            background-color: lighten($color, 10%) !important;
          }

          &.DayPicker-Day--disabled {
            color: #e7e7e7;
            background-color: transparent;

            &:hover {
              color: #e7e7e7 !important;
              background-color: transparent !important;
            }
          }
        }
      }
    }
  }
}
