.header {
  $p: &;

  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;

  width: 100%;

  background-color: map-get($colors, 'main');

  // &_sticked {
  //   background-color: map-get($colors, 'main');

  //   transition: 0.2s;
  // }

  &__content {
    position: relative;

    display: flex;
    align-items: center;
    height: 63px;
  }

  &__logo {
    display: flex;
    align-items: center;
    width: 120px;
    height: 100%;
    margin-right: auto;
    padding-left: 5px;
    padding-right: 5px;

    background-color: #dedace;
  }

  &__block {
    // display: none;
  }

  &__nav {
    display: none;

    span {
      transition: border 0.2s;
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;
    }

    a:hover {
      span {
        border-bottom-color: #fff;
      }
    }
  }

  &__phones {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 20px;
  }

  &__phone {
    font-size: 13px;
    font-weight: 600;
    line-height: 1.23;

    color: #fff;

    transition: color 0.2s;

    &:hover {
      color: #000;
    }
  }

  &__callback {
    display: none;

    transition: color 0.2s, background 0.2s;

    &:hover {
      color: map-get($colors, 'main');
      background-color: #fff;
    }
  }

  &__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 42px;
    height: 42px;

    &::before {
      width: 33px;
      height: 2px;
      margin-bottom: 8px;

      background-color: #fff;

      transition: background 0.2s;

      content: '';
    }

    &::after {
      width: 33px;
      height: 2px;

      transition: background 0.2s;

      background-color: #fff;

      content: '';
    }

    span {
      width: 17px;
      height: 2px;
      margin-bottom: 8px;

      font-size: 12px;
      line-height: 1.25;
      text-transform: uppercase;

      background-color: #fff;

      transition: background 0.2s;
    }

    // &:hover {
    //   span {
    //     background-color: #000;
    //   }

    //   &::before {
    //     background-color: #000;
    //   }

    //   &::after {
    //     background-color: #000;
    //   }
    // }
  }

  &_opened {
    #{$p}__block {
      position: absolute;
      top: 0;
      right: -20px;

      display: flex;
      flex-direction: column;
      width: 259px;
      padding-top: 80px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 50px;

      background-color: map-get($colors, 'main');
    }

    #{$p}__nav {
      display: flex;
      flex-direction: column;
      margin-bottom: 100px;

      a {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 50px;

        font-size: 14px;
        line-height: 1.64;
        text-transform: uppercase;

        color: #fff;

        // transition: color 0.2s;

        // &:hover {
        //   color: map-get($colors, 'second');
        // }

        &:not(:last-child) {
          border-bottom: 1px solid rgba(255, 255, 255, 0.18);
        }
      }
    }

    #{$p}__info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    #{$p}__phones {
      margin-right: 0;
      margin-bottom: 23px;
    }

    #{$p}__phone {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.23;
    }

    #{$p}__callback {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 179px;
      height: 52px;

      font-size: 14px;
      text-transform: uppercase;

      border: 2px solid #fff;
      color: #fff;

      &:hover {
        color: map-get($colors, 'main');
        background-color: #fff;
      }
    }

    #{$p}__button {
      position: relative;

      align-items: center;

      span {
        display: none;
      }

      &::before {
        width: 32px;
        margin-bottom: 0;

        background-color: #fff;
        transform: rotate(45deg);
      }

      &::after {
        width: 32px;
        margin-top: -1px;

        background-color: #fff;
        transform: rotate(-45deg);
      }
    }
  }
}

// @media (min-width: 576px) {
//   .contacts {
//     &__map {
//       margin-left: calc((100vw - 540px) / -2 - 15px);
//       margin-right: calc((100vw - 540px) / -2 - 15px);
//     }
//   }
// }

@media (min-width: 768px) {
  .header {
    $p: &;

    &__content {
      height: 106px;
    }

    &__logo {
      width: 152px;
    }

    &__nav {
      width: 262px;
      padding-top: 107px;
      padding-left: 41px;
      padding-right: 23px;
      padding-bottom: 76px;
    }

    &__info {
      display: flex;
      align-items: center;
      margin-right: 42px;
    }

    &__phones {
      margin-right: 23px;
    }

    &__phone {
      font-size: 18px;
    }

    &__callback {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 179px;
      height: 52px;

      font-size: 14px;
      text-transform: uppercase;

      border: 2px solid #fff;
      color: #fff;
    }

    &__button {
      &::before {
        width: 37px;
        margin-bottom: 9px;
      }

      &::after {
        width: 37px;
      }

      span {
        width: 18px;
        margin-bottom: 9px;
      }
    }

    &_opened {
      #{$p}__block {
        position: initial;

        display: initial;
        width: initial;
        padding: initial;

        background-color: initial;
      }

      #{$p}__nav {
        position: absolute;
        top: 0;
        right: -20px;

        background-color: map-get($colors, 'main');
      }

      #{$p}__info {
        flex-direction: row;
        align-items: center;
      }

      #{$p}__phones {
        margin-right: 23px;
        margin-bottom: 0;
      }

      #{$p}__phone {
        font-size: 18px;
      }
    }
  }
}

// @media (min-width: 992px) {
//   .header {
//     &__nav {
//       left: calc((100vw - 960px) / -2 - 20px);
//       right: calc((100vw - 960px) / -2 - 20px);
//     }
//   }
// }

@media (min-width: 1200px) {
  .header {
    &__logo {
      // margin-right: 73px;
      margin-right: 23px;
    }

    &__block {
      flex-grow: 1;
      display: flex;
      align-items: center;
    }

    &__nav {
      display: flex;
      column-gap: 22px;
      width: auto;
      margin-right: auto;
      padding: 0;

      a {
        font-size: 14px;
        line-height: 1.64;
        text-transform: uppercase;

        color: #fff;
      }
    }

    &__info {
      margin-right: 0;
    }

    &__button {
      display: none;
    }
  }
}

// @media (hover: hover) {
//   .header {
//     &__nav {
//       a {
//         &:hover {
//           color: map-get($colors, 'main-light');
//         }
//       }
//     }

//     &__phone {
//       &:hover {
//         color: map-get($colors, 'main');
//       }
//     }

//     &__callback {
//       &:hover {
//         color: #fff;
//         background-color: map-get($colors, 'main');
//       }
//     }
//   }
// }
