.nutrition {
  &__content {
    display: flex;
    flex-direction: column;
    padding-bottom: 61px;
  }

  &__first {
    margin-bottom: 32px;
  }

  &__title {
    margin-bottom: 21px;

    font-size: 25px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }

  &__schedule {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    text-align: center;
  }

  &__schedule strong {
    margin-bottom: 3px;

    font-weight: 600;

    color: #92855b;
  }

  &__text {
    margin-bottom: 24px;
    padding: 16px;

    line-height: 1.82;
    text-align: center;

    background-color: #faf9f7;
  }

  &__text p:not(:last-child) {
    margin-bottom: 16px;
  }

  &__assortment {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 7px;
    column-gap: 20px;
  }

  &__assortment span {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.74;
  }

  &__assortment span::before {
    width: 5px;
    height: 5px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: #92855b;
    content: '';
  }

  &__slider {
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
  }

  &__slide {
    display: flex;
    padding-bottom: 93%;
  }

  &__slide img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    aspect-ratio: 1;

    transform: translateY(-50%);

    &::before {
      width: 33px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(4px);

      transition: transform 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      left: 18px;

      width: 7px;
      aspect-ratio: 1;

      border-top: 1px solid map-get($colors, 'main');
      border-left: 1px solid map-get($colors, 'main');
      transform: rotate(-45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &:active {
      &::before {
        transform: scale(0.9);
      }
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    aspect-ratio: 1;

    transform: translateY(-50%);

    &::before {
      width: 33px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(4px);

      transition: transform 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      right: 18px;

      width: 7px;
      aspect-ratio: 1;

      border-top: 1px solid map-get($colors, 'main');
      border-right: 1px solid map-get($colors, 'main');
      transform: rotate(45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &:active {
      &::before {
        transform: scale(0.9);
      }
    }
  }
}

@media (min-width: 768px) {
  .nutrition {
    &__content {
      padding-bottom: 100px;
    }

    &__first {
      justify-content: center;
    }

    &__title {
      font-size: 38px;
    }

    &__slider {
      margin: 0;
    }

    &__slide {
      padding-bottom: 65%;
    }

    &__prev {
      left: 20px;

      &::before {
        width: 39px;
      }

      &::after {
        width: 8px;
      }
    }

    &__next {
      right: 20px;

      &::before {
        width: 39px;
      }

      &::after {
        width: 8px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .nutrition {
    &__content {
      flex-direction: row;
      padding-bottom: 116px;
    }

    &__first {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      padding-right: 30px;
    }

    &__title {
      max-width: 400px;
      align-self: center;
    }

    &__second {
      flex-shrink: 0;
      width: 500px;
    }

    &__slide {
      padding-bottom: 664px;
    }
  }
}
