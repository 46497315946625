/// Native `url(..)` function wrapper
/// @param {String} $base - base URL for the asset
/// @param {String} $type - asset type folder (e.g. `fonts/`)
/// @param {String} $path - asset path
/// @return {Url}
@function asset($base, $type, $path) {
  @return url($base + $type + $path);
}

/// Returns URL to an image based on its path
/// @param {String} $path - image path
/// @param {String} $base [$base-url] - base URL
/// @return {Url}
/// @require $base-url
@function image($path, $base: $base-url) {
  @return asset($base, 'images/', $path);
}

/// Returns URL to a font based on its path
/// @param {String} $path - font path
/// @param {String} $base [$base-url] - base URL
/// @return {Url}
/// @require $base-url
@function font($path, $base: $base-url) {
  @return asset($base, 'fonts/', $path);
}

// ----
// libsass (v3.3.6)
// ----
// =====================================================================
//
//  SVG ENCODER FOR SASS
//  -------------------------
//  Indrek Paas <@indrekpaas>
//
//  Inspired by Taylor Hunt's "Optimizing SVGs in data URIs"
//  http://codepen.io/tigt/post/optimizing-svgs-in-data-uris
//
//
//  10.12.2016 Remove unnecessary encodings
//  09.11.2016 Add encoding for all reserved and unsafe characters
//  01.11.2016 Convert double quotes to single quotes
//  30.10.2016 Add missing namespace support
//  30.10.2016 Initial release
//
// =====================================================================
@function svg-encode($svg) {
  // Add missing namespace
  @if not str-index($svg, ' xmlns="http://www.w3.org/2000/svg"') {
    $svg: str-insert($svg, ' xmlns="http://www.w3.org/2000/svg"', 5);
  }

  // Encode characters
  $encode: (
    '"': '%22',
    '#': '%23',
    '%': '%25',
    '<': '%3C',
    '>': '%3E',
    '{': '%7B',
    '}': '%7D',
  );

  $string: '';
  $character: '';

  @for $i from 1 through str-length($svg) {
    $character: str-slice($svg, $i, $i);

    @if index(map-keys($encode), $character) {
      $character: map-get($encode, $character);
    }

    $string: $string + $character;
  }

  // Return data URI
  @return url('data:image/svg+xml,#{$string}');
}
// Usage ---------------------------------------------------------------
element {
  background-image: svg-encode('<svg></svg>');
}
