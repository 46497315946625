.hotels {
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 20px;

  width: 140px;

  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 21px rgb(0 0 0/4%), 0 3px 13px rgb(0 0 0/18%);

  &__toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    cursor: pointer;

    span {
      font-size: 14px;
      font-weight: 500;
    }

    &::after {
      content: '';
      width: 8px;
      height: 8px;
      margin-top: -4px;

      border-left: 1px solid #000;
      border-bottom: 1px solid #000;
      transform: rotate(-45deg);
    }
  }

  &_closed &__toggle {
    &::after {
      margin-top: 4px;

      transform: rotate(-225deg);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  &_closed &__list {
    display: none;
  }

  &__item {
    display: flex;
    align-items: center;

    cursor: pointer;
  }

  &__image {
    position: relative;

    width: 26px;
    height: 26px;
    margin-right: 8px;
    padding: 1px;

    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #bbb;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;

      border-radius: 50%;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-size: 14px;
    color: #333;
  }

  &__address {
    font-size: 9px;
    color: #777;
  }
}
