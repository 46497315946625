.contacts {
  position: relative;

  background-position: center;
  background-size: cover;
  background-color: #faf9f7;

  #contacts {
    position: absolute;
    top: -63px;
  }

  &__content {
    padding-top: 53px;
    padding-bottom: 72px;
  }

  &__title {
    margin-bottom: 25px;

    font-size: 25px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }

  &__block {
    margin-bottom: 35px;
  }

  &__address {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;

    span {
      margin-bottom: 3px;

      font-size: 12px;
      line-height: 1.25;
      text-transform: uppercase;

      color: rgba(0, 0, 0, 0.54);
    }

    strong {
      font-size: 14px;
      line-height: 1.21;
      text-align: center;
    }
  }

  &__phone {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;

    span {
      margin-bottom: 3px;

      font-size: 12px;
      line-height: 1.25;
      text-transform: uppercase;

      color: rgba(0, 0, 0, 0.54);
    }

    a {
      font-size: 14px;
      line-height: 1.21;
      text-align: center;

      transition: color 0.2s;

      &:hover {
        color: map-get($colors, 'main');
      }
    }
  }

  &__email {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;

    span {
      margin-bottom: 3px;

      font-size: 12px;
      line-height: 1.25;
      text-transform: uppercase;

      color: rgba(0, 0, 0, 0.54);
    }

    a {
      font-size: 14px;
      line-height: 1.21;
      text-align: center;

      transition: color 0.2s;

      &:hover {
        color: map-get($colors, 'main');
      }
    }
  }

  &__requisites {
    display: block;
    justify-content: center;
    align-items: center;
    height: 52px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    padding-left: 40px;
    padding-right: 40px;

    font-size: 14px;
    text-transform: uppercase;

    color: #fff;
    background-color: map-get($colors, 'main');

    transition: background 0.2s;

    &:hover {
      background-color: map-get($colors, 'main-dark');
    }
  }

  &__networks {
    div {
      display: flex;
      justify-content: center;
      column-gap: 10px;
      margin-bottom: 13px;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 43px;
      aspect-ratio: 1;

      // border: 1px solid map-get($colors, 'main');
      // border-radius: 50%;
    }

    p {
      font-size: 12px;
      line-height: 1.25;
      text-align: center;

      color: map-get($colors, 'main');
    }
  }

  &__map {
    aspect-ratio: 1 / 0.95;
  }
}

// @media (min-width: 576px) {
//   .contacts {
//     &__block {
//       margin-left: calc((100vw - 540px) / -2);
//       margin-right: calc((100vw - 540px) / -2);
//     }
//   }
// }

@media (min-width: 768px) {
  .contacts {
    #contacts {
      top: -106px;
    }

    &__content {
      padding-top: 62px;
      padding-bottom: 113px;
    }

    &__title {
      margin-bottom: 52px;

      font-size: 35px;
      text-align: left;
    }

    &__wrapper {
      display: flex;
    }

    &__block {
      width: 310px;
      margin-bottom: 0;
      padding-right: 20px;
    }

    &__address {
      align-items: flex-start;

      span {
        margin-bottom: 7px;

        font-size: 14px;
      }

      strong {
        font-size: 16px;
        text-align: left;
      }
    }

    &__phone {
      align-items: flex-start;

      span {
        margin-bottom: 7px;

        font-size: 14px;
      }

      a {
        font-size: 16px;
      }
    }

    &__email {
      align-items: flex-start;

      span {
        margin-bottom: 7px;

        font-size: 14px;
      }

      a {
        font-size: 16px;
      }
    }

    &__requisites {
      margin-left: 0;
      margin-bottom: 40px;
    }

    &__networks {
      div {
        justify-content: flex-start;
      }

      p {
        text-align: left;
      }
    }

    &__map {
      flex-grow: 1;
      aspect-ratio: auto;
    }
  }
}

@media (min-width: 1200px) {
  .contacts {
    &__content {
      padding-top: 102px;
      padding-bottom: 120px;
    }

    &__title {
      margin-bottom: 57px;
    }

    &__block {
      width: 400px;
    }

    &__address {
      margin-bottom: 38px;

      strong {
        font-size: 20px;
      }
    }

    &__phone {
      margin-bottom: 44px;

      a {
        font-size: 20px;
      }
    }

    &__email {
      margin-bottom: 44px;

      a {
        font-size: 20px;
      }
    }

    &__requisites {
      margin-bottom: 130px;
    }
  }
}
