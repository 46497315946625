.reviews {
  $p: &;

  position: relative;

  // background-color: map-get($colors, 'bg');

  #reviews {
    position: absolute;
    top: -63px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-top: 53px;
    padding-bottom: 107px;
  }

  &__title {
    margin-bottom: 30px;

    font-size: 25px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }

  &__slider {
    position: relative;

    margin-bottom: 35px;
  }

  &__slide {
    padding-top: 30px;
    padding-left: 22px;
    padding-right: 31px;
    padding-bottom: 15px;
    background-color: #faf9f7;

    &_clipped {
      #{$p}__text {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      #{$p}__button {
        visibility: visible;
      }
    }
  }

  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__name {
    margin-right: 9px;

    font-size: 18px;
    font-weight: 600;
    line-height: 1.22;
    text-transform: uppercase;
  }

  &__date {
    font-size: 14px;
    line-height: 1.21;

    color: rgba(0, 0, 0, 0.55);
  }

  &__text {
    font-size: 14px;
    line-height: 1.5;
  }

  &__button {
    margin-bottom: 20px;

    font-size: 12px;
    font-weight: 600;
    line-height: 1.17;
    text-transform: uppercase;

    color: map-get($colors, 'main');
    visibility: hidden;

    transition: color 0.2s;

    &:hover {
      color: map-get($colors, 'main-dark');
    }
  }

  &__grade {
    font-weight: 600;
    line-height: 1.25;

    color: rgba(0, 0, 0, 0.55);
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: -20px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    aspect-ratio: 1;

    transform: translateY(-50%);

    &::before {
      width: 33px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: map-get($colors, 'main');

      transition: transform 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      left: 18px;

      width: 7px;
      aspect-ratio: 1;

      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
      transform: rotate(-45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &:active {
      &::before {
        transform: scale(0.9);
      }
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: -20px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    aspect-ratio: 1;

    transform: translateY(-50%);

    &::before {
      width: 33px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: map-get($colors, 'main');

      transition: transform 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      right: 18px;

      width: 7px;
      aspect-ratio: 1;

      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: rotate(45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &:active {
      &::before {
        transform: scale(0.9);
      }
    }
  }

  &__add {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 202px;
    height: 52px;

    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;

    border: 1px solid #000;
    color: map-get($colors, 'main');

    transition: border 0.2s, color 0.2s, background 0.2s;

    &:hover {
      border-color: map-get($colors, 'main');
      color: #fff;
      background-color: map-get($colors, 'main');
    }
  }
}

@media (min-width: 768px) {
  .reviews {
    #reviews {
      top: -106px;
    }

    &__content {
      padding-top: 62px;
      padding-bottom: 79px;
    }

    &__title {
      margin-bottom: 44px;

      font-size: 35px;
    }

    &__slider {
      margin-bottom: 44px;
    }

    &__slide {
      padding-bottom: 35px;
    }

    &__prev {
      &::before {
        width: 39px;
      }

      &::after {
        width: 8px;
      }
    }

    &__next {
      &::before {
        width: 39px;
      }

      &::after {
        width: 8px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .reviews {
    &__content {
      padding-top: 108px;
      padding-bottom: 111px;
    }

    &__slider {
      margin-bottom: 42px;
    }

    &__prev {
      left: -80px;

      width: 58px;

      &::before {
        width: 58px;
      }

      &::after {
        top: 23px;
        left: 26px;

        width: 12px;
      }
    }

    &__next {
      right: -80px;

      width: 58px;

      &::before {
        width: 58px;
      }

      &::after {
        top: 23px;
        right: 26px;

        width: 12px;
      }
    }
  }
}
