.screen {
  padding-top: 63px;

  background-position: center;
  background-size: cover;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 72px;
  }

  &__networks {
    display: flex;
    justify-content: center;
    column-gap: 16px;
    margin-bottom: 28px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 27px;
      aspect-ratio: 1;

      // border: 1px solid #dedacd;
      // border-radius: 50%;
    }
  }

  &__title {
    max-width: 320px;
    margin-bottom: 14px;

    font-size: 34px;
    font-weight: 600;
    line-height: 1.1302;
    text-transform: uppercase;
    text-align: center;

    color: #fff;
  }

  &__subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 7px;
    margin-bottom: 21px;

    font-weight: 600;
    line-height: 1.25;
    text-transform: uppercase;

    color: #fff;

    &::before {
      width: 14px;
      height: 1px;

      background-color: #fff;

      content: '';
    }

    &::after {
      width: 14px;
      height: 1px;

      background-color: #fff;

      content: '';
    }
  }

  &__text {
    max-width: 300px;
    margin-bottom: 37px;

    font-size: 12px;
    font-weight: 500;
    line-height: 1.405;
    text-align: center;

    color: #fff;
  }

  &__directions {
    display: flex;
    column-gap: 15px;
    margin-bottom: 37px;
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 13px;

    background-color: rgba(255, 255, 255, 0.9);

    p {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 92px;
    }

    img {
      width: 26px;
      aspect-ratio: 1;
      margin-bottom: 7px;
      object-fit: contain;
    }

    span {
      font-size: 12px;
      line-height: 1.25;
    }

    strong {
      font-size: 12px;
      font-weight: 600;
      line-height: 1.25;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__calculation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 279px;
    height: 52px;
    margin-bottom: 10px;

    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;

    color: #fff;
    background-color: map-get($colors, 'main');

    transition: background 0.2s;

    &:hover {
      background-color: map-get($colors, 'main-dark');
    }
  }

  &__booking {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 279px;
    height: 52px;

    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;

    border: 1px solid #fff;
    color: #fff;

    transition: color 0.2, background 0.2s;

    &:hover {
      color: map-get($colors, 'main');
      background-color: #fff;
    }
  }
}

// @media (min-width: 576px) {
//   .screen {
//     &__bg {
//       margin-left: calc((100vw - 540px) / -2 - 20px);
//       margin-right: calc((100vw - 540px) / -2 - 20px);
//     }
//   }
// }

@media (min-width: 768px) {
  .screen {
    padding-top: 106px;

    &__content {
      padding-top: 50px;
      padding-bottom: 93px;
    }

    &__networks {
      a {
        width: 36px;
      }
    }

    &__title {
      max-width: none;
      margin-bottom: 30px;

      font-size: 58px;
    }

    &__subtitle {
      column-gap: 10px;
      margin-bottom: 38px;

      font-size: 25px;

      &::before {
        width: 65px;
        height: 2px;
      }

      &::after {
        width: 65px;
        height: 2px;
      }
    }

    &__text {
      max-width: 540px;

      font-size: 16px;
      line-height: 1.64;
    }

    &__directions {
      column-gap: 43px;
      margin-bottom: 27px;
      padding-left: 26px;
      padding-right: 26px;
    }

    &__buttons {
      flex-direction: row;
    }

    &__calculation {
      width: 195px;
      margin-right: 11px;
      margin-bottom: 0;
    }

    &__booking {
      width: 179px;
      margin-left: 11px;
    }
  }
}

// @media (min-width: 992px) {
//   .screen {
//     &__bg {
//       margin-left: calc((100vw - 960px) / -2 - 20px);
//     }

//     &__image {
//       left: calc((100vw - 960px) / -2 - 20px);
//     }
//   }
// }

@media (min-width: 1200px) {
  .screen {
    &__title {
      margin-bottom: 10px;

      font-size: 74px;
    }
  }
}
