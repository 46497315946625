.gorgippia {
  // background-color: map-get($colors, 'bg');

  &__content {
    padding-top: 60px;
    padding-bottom: 87px;
  }

  &__title {
    margin-bottom: 54px;

    font-size: 25px;
    font-weight: 600;
    line-height: 1.384;
    text-align: center;

    span {
      color: map-get($colors, 'main');
    }
  }

  &__gallery {
    display: flex;
    flex-direction: column;
  }

  &__first {
    display: flex;
    margin-bottom: 18px;
  }

  &__one {
    display: flex;
    aspect-ratio: 1 / 0.95;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__second {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
  }

  &__two {
    display: flex;
    aspect-ratio: 1 / 0.65;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__three {
    display: flex;
    aspect-ratio: 1 / 0.65;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media (min-width: 768px) {
  .gorgippia {
    &__content {
      padding-top: 73px;
      padding-bottom: 80px;
    }

    &__title {
      max-width: 714px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 35px;

      font-size: 35px;
    }

    &__gallery {
      flex-direction: row;
      column-gap: 18px;
    }

    &__first {
      width: calc(60% - 9px);
      margin-bottom: 0;
    }

    &__second {
      width: calc(40% - 9px);
    }

    &__two {
      flex-grow: 1;
    }

    &__three {
      flex-grow: 1;
    }
  }
}

@media (min-width: 1200px) {
  .gorgippia {
    &__content {
      padding-top: 112px;
      padding-bottom: 108px;
    }

    &__title {
      margin-bottom: 50px;
    }

    &__gallery {
      column-gap: 30px;
    }

    &__first {
      width: calc(60% - 15px);
    }

    &__second {
      row-gap: 30px;
      width: calc(40% - 15px);
    }
  }
}
