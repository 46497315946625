.module {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 164px;
}

.adults {
  margin-bottom: 34px;
}

.babies {
}

.babyList {
  margin-top: 34px;
}
