.module {
  position: relative;

  width: 770px;
  max-width: 100%;
  padding-top: 79px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 75px;
  overflow: hidden;

  background-color: #faf9f7;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.top {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.name {
  margin-right: 9px;

  font-size: 18px;
  font-weight: 600;
  line-height: 1.22;
}

.date {
  font-size: 14px;
  line-height: 1.21;

  color: rgba(0, 0, 0, 0.55);
}

.text {
  margin-bottom: 21px;

  font-size: 14px;
  line-height: 1.5;
}

.grade {
  font-weight: 600;
  line-height: 1.25;
  text-transform: uppercase;

  color: #92855b;
}

@media (min-width: 768px) {
  .module {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  .module {
    padding-left: 100px;
    padding-right: 100px;
  }
}
