.module {
  position: relative;

  width: 770px;
  max-width: 100%;
  padding-top: 64px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 80px;
  overflow: hidden;

  background-color: #faf9f7;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.form {
  position: relative;

  display: flex;
  flex-direction: column;
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  margin-bottom: 67px;

  font-size: 35px;
  font-weight: 600;
  line-height: 1.23;
  text-align: center;
}

.arrival {
  margin-bottom: 24px;
}

.departure {
  margin-bottom: 24px;
}

.adults {
  margin-bottom: 24px;
}

.babies {
  margin-bottom: 24px;
}

.babyList {
  margin-bottom: 24px;
}

.name {
  margin-bottom: 24px;
}

.phone {
  margin-bottom: 42px;
}

.button {
  align-self: center;
  width: 277px;
  height: 50px;
  margin-bottom: 10px;

  font-weight: 600;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;

  color: #fff;
  background-color: #92855b;

  transition: background 0.2s;

  &:hover {
    background-color: darken(#92855b, 10%);
  }
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
}
