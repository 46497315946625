.about {
  $p: &;

  position: relative;

  // background-color: map-get($colors, 'bg');

  #about {
    position: absolute;
    top: -63px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-top: 45px;
  }

  &__title {
    margin-bottom: 21px;

    font-size: 25px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }

  &__subtitle {
    margin-bottom: 22px;
    padding-bottom: 27px;

    font-size: 18px;
    line-height: 1.42;
    text-align: center;

    border-bottom: 2px solid map-get($colors, 'main');
  }

  &__text {
    font-size: 14px;
    line-height: 1.64;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .about {
    #about {
      top: -106px;
    }

    &__content {
      padding-top: 57px;
    }

    &__title {
      margin-bottom: 28px;

      font-size: 35px;
    }

    &__subtitle {
      max-width: 658px;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 23px;

      font-size: 23px;
    }

    &__text {
      font-size: 16px;
    }
  }
}

@media (min-width: 1200px) {
  .about {
    &__content {
      padding-top: 95px;
    }

    &__text {
      max-width: 852px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
